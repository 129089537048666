import React, { useState } from 'react'
import useSWR from 'swr'
import {
  Container,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
} from '@chakra-ui/react'
import { useAuth } from '../../../providers/AuthProvider'
import TableLoader from '../../../components/TableLoader'

interface Log {
  date: string
  logs: string[]
}

const Logs = () => {
  const { authenticatedAxios } = useAuth()
  const fetcher = (url: string) => authenticatedAxios.get(url).then((res) => res.data)
  const { data, error } = useSWR<{ success: boolean; logs: Log[] }>('/api/logs', fetcher)

  const { isOpen, onOpen, onClose } = useDisclosure()
  const [selectedLog, setSelectedLog] = useState<Log | null>(null)

  if (!data) return <TableLoader numRows={3} columns={['Date', 'Log Entries']} error={error} />

  const handleRowClick = (log: Log) => {
    setSelectedLog(log)
    onOpen()
  }

  return (
    <Container>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>Date</Th>
            <Th>Log Entries</Th>
          </Tr>
        </Thead>
        <Tbody>
          {data.logs.map((log) => (
            <Tr key={log.date} onClick={() => handleRowClick(log)} cursor="pointer">
              <Td>{new Date(log.date).toLocaleDateString()}</Td>
              <Td>{log.logs.length}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Logs for {selectedLog && new Date(selectedLog.date).toLocaleDateString()}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box as="pre" whiteSpace="pre-wrap">
              {selectedLog && selectedLog.logs.join('\n')}
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Container>
  )
}

export default Logs
