import React from 'react'
import { useAuth } from '../../providers/AuthProvider'
import { Tabs, TabList, Tab, TabPanels, TabPanel, Text, Flex, Spacer, HStack, Button, Box } from '@chakra-ui/react'
import { Outlet, NavLink } from 'react-router-dom'

const Main = () => {
  const auth = useAuth()

  return (
    <>
      <Flex>
        <Spacer />
        <HStack spacing={5} p={5}>
          <NavLink to="user">
            <Text sx={{ color: 'primary.500', fontWeight: 'semibold' }}>@{auth.userDetails?.username}</Text>
          </NavLink>
          <Button onClick={auth.logout}>Logout</Button>
        </HStack>
      </Flex>
      <Tabs variant="line" orientation="vertical" align="start">
        <TabList>
          <NavLink to="courses">
            <Tab>Courses</Tab>
          </NavLink>
          <NavLink to="logs">
            <Tab>Logs</Tab>
          </NavLink>
        </TabList>
        <Outlet />
      </Tabs>
    </>
  )
}

export default Main
