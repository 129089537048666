import React, { useState, useEffect } from 'react'
import { Button, Container, Flex, Textarea, Spinner } from '@chakra-ui/react'
import { useAuth } from '../../../providers/AuthProvider'
import { useToast } from '@chakra-ui/react'

const Profile = () => {
  const [textAreaContent, setTextAreaContent] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(true)
  const { userToken, authenticatedAxios } = useAuth()
  const toast = useToast()

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        setLoading(true)
        const response = await authenticatedAxios.get('/api/settings')
        setTextAreaContent(JSON.stringify(response.data.settings))
      } catch (error) {
        setTextAreaContent(JSON.stringify({}))
        toast({
          title: 'Error fetching the settings',
          description: 'An error occurred. Please try again.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        })
      } finally {
        setLoading(false)
      }
    }

    fetchSettings()
  }, [userToken])

  const save = async () => {
    try {
      setLoading(true)
      const response = await authenticatedAxios.post('/api/settings', JSON.parse(textAreaContent))
    } catch (error) {
      toast({
        title: 'Error saving the settings',
        description: 'An error occurred. Please try again.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <Container>
      {loading ? (
        <Flex justify="center" align="center" height="100vh">
          <Spinner size="xl" />
        </Flex>
      ) : (
        <Flex direction="column" align="flex-end">
          <Textarea rows={15} value={textAreaContent} onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setTextAreaContent(e.target.value)} />
          <Button colorScheme="primary" mt={3} alignSelf="flex-end" onClick={save}>
            Save
          </Button>
        </Flex>
      )}
    </Container>
  )
}

export default Profile
