import React, { useState } from 'react'
import useSWR, { mutate } from 'swr'
import { Container, Table, Thead, Tbody, Tr, Th, Td, IconButton, Box, useToast } from '@chakra-ui/react'
import { DeleteIcon, AddIcon, NotAllowedIcon } from '@chakra-ui/icons'
import { useAuth } from '../../../providers/AuthProvider'
import Loader from '../../../components/TableLoader'
import AddCourseModal from './AddCourseModal'

interface Course {
  _id: string
  weekDay: string
  time: string
  name: string
  skip: boolean
}

const dayMap: { [key: string]: string } = {
  '0': 'Sunday',
  '1': 'Monday',
  '2': 'Tuesday',
  '3': 'Wednesday',
  '4': 'Thursday',
  '5': 'Friday',
  '6': 'Saturday',
}

const Courses = () => {
  const { authenticatedAxios } = useAuth()
  const fetcher = (url: string) => authenticatedAxios.get(url).then((res) => res.data)
  const { data, error } = useSWR<{ courses: Course[] }>('/api/courses', fetcher)
  const toast = useToast()
  const [isModalOpen, setIsModalOpen] = useState(false)

  const handleDelete = async (id: string) => {
    try {
      await authenticatedAxios.delete(`/api/courses/${id}`)
      mutate('/api/courses')
    } catch (err) {
      toast({
        title: 'Failed to delete the course.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    }
  }

  const handleSkip = async (id: string) => {
    try {
      await authenticatedAxios.patch(`/api/courses/${id}/skip`)
      mutate('/api/courses')
    } catch (err) {
      toast({
        title: 'Failed to skip the course.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    }
  }

  if (error || !data) return <Loader error={error} numRows={3} columns={['Weekday', 'Time', 'Course Name', 'Actions']} />

  return (
    <Container>
      <Table variant="simple" mt={4}>
        <Thead>
          <Tr>
            <Th>Weekday</Th>
            <Th>Time</Th>
            <Th>Course Name</Th>
            <Th>Actions</Th>
          </Tr>
        </Thead>
        <Tbody>
          {data.courses.map((course) => (
            <Tr key={course._id} opacity={course.skip ? 0.3 : 1}>
              <Td>{dayMap[course.weekDay]}</Td>
              <Td>{course.time}</Td>
              <Td>{course.name}</Td>
              <Td>
                <IconButton
                  aria-label="Delete course"
                  icon={<DeleteIcon />}
                  size="sm"
                  variant="ghost"
                  color="red.500"
                  onClick={() => handleDelete(course._id)}
                  mr={2}
                />
                <IconButton
                  aria-label="Skip course"
                  icon={<NotAllowedIcon />}
                  size="sm"
                  variant="ghost"
                  color="blue.500"
                  onClick={() => handleSkip(course._id)}
                />
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      <Box display="flex" justifyContent="flex-end" mt={4}>
        <IconButton aria-label="Add course" icon={<AddIcon />} colorScheme="red" onClick={() => setIsModalOpen(true)} size="sm" variant="outline" />
      </Box>
      <AddCourseModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
    </Container>
  )
}

export default Courses
