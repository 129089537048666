import { extendTheme, Tabs } from '@chakra-ui/react'

export default extendTheme({
  colors: {
    primary: {
      50: '#ffffff',
      100: '#ffffff',
      200: '#def6ff',
      300: '#b5dffb',
      400: '#8bc7f3',
      500: '#5fb1ed',
      600: '#359be6',
      700: '#1e81cd',
      800: '#1265a0',
      900: '#074873',
    },
    secondary: {
      50: '#e3e4e9',
      100: '#c2c4ce',
      200: '#a1a4b3',
      300: '#808497',
      400: '#2D3047',
      500: '#1a1c2e',
      600: '#151825',
      700: '#11141d',
      800: '#0d1015',
      900: '#090b0e',
    },
  },
  fonts: {
    heading: 'Sora, sans-serif',
    body: 'Sora, sans-serif',
  },
  components: {
    Button: {
      baseStyle: {
        fontWeight: 'mediun',
        borderRadius: 'none',
      },
      defaultProps: {
        colorScheme: 'primary',
      },
    },
    Tabs: {
      defaultProps: {
        colorScheme: 'primary',
      },
    },
  },
})
