import React from 'react'
import logo from './logo.png'
import TelegramLoginButton, { TelegramUser } from 'telegram-login-button'
import { useAuth } from '../../providers/AuthProvider'
import { useNavigate } from 'react-router-dom'
import { Box, Flex, Heading, Image, Skeleton, Stack } from '@chakra-ui/react'
import { useToast } from '@chakra-ui/react'

const Login = () => {
  const auth = useAuth()
  let navigate = useNavigate()
  const toast = useToast()

  const telegramBot = process.env.TELEGRAM_BOT
  const login = async (user: TelegramUser) => {
    try {
      await auth.login(user)
      navigate('/')
    } catch (error) {
      toast({
        title: 'Login Failed',
        description: 'An error occurred during login. Please try again.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      })
    }
  }

  return (
    <Box maxW="7xl" mx="auto" px={{ base: '0', lg: '12' }} py={{ base: '0', lg: '12' }}>
      <Stack direction={{ base: 'column-reverse', lg: 'row' }} spacing={{ base: '0', lg: '20' }}>
        <Box
          width={{ lg: 'sm' }}
          transform={{ base: 'translateY(-50%)', lg: 'none' }}
          bg={{ base: 'primary.200', lg: 'transparent' }}
          mx={{ base: '6', md: '8', lg: '0' }}
          px={{ base: '6', md: '8', lg: '0' }}
          py={{ base: '6', md: '8', lg: '12' }}
        >
          <Stack spacing={{ base: '8', lg: '10' }}>
            <Stack spacing={{ base: '2', lg: '4' }}>
              <Heading size="xl" color="primary.500">
                Elrod
              </Heading>
              <Heading size="xl" fontWeight="normal" color="secondary">
                Your gym buddy.
              </Heading>
            </Stack>
            {telegramBot && <TelegramLoginButton botName={telegramBot} dataOnauth={login} cornerRadius={0} />}{' '}
          </Stack>
        </Box>
        <Flex overflow="hidden">
          <Image display={{ base: 'none', sm: 'initial' }} src={logo} alt="Elrod" fallback={<Skeleton />} maxH="450px" objectFit="cover" />
        </Flex>
      </Stack>
    </Box>
  )
}
export default Login
