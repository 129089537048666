import React from 'react'
import { Container, Heading } from '@chakra-ui/react'

const PageNotFound = () => {
  return (
    <Container p={10}>
      <Heading paddingTop={5} textAlign="center" color="primary.500">
        Page not found
      </Heading>
    </Container>
  )
}

export default PageNotFound
