import React, { useState, useEffect } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Select,
  useToast,
  Table,
  Thead,
  Tr,
  Th,
  Td,
  Tbody,
} from '@chakra-ui/react'
import { useAuth } from '../../../providers/AuthProvider'
import { mutate } from 'swr'
import Loader from '../../../components/TableLoader'
import VisibleIf from '../../../components/VisibleIf'

type AvailableCourse = {
  name: string
  time: string
}

type AvailableCourses = AvailableCourse[] | null | Error

const AvailableCourses: React.FC<{ availableCourses: AvailableCourses; onRowClick: (course: AvailableCourse) => void }> = ({
  availableCourses,
  onRowClick,
}) => {
  if (!availableCourses || availableCourses instanceof Error) {
    return <Loader numRows={3} columns={['Course Name', 'Time']} error={availableCourses instanceof Error} />
  }

  return (
    <Table variant="simple" mt={4}>
      <Thead>
        <Tr>
          <Th>Course Name</Th>
          <Th>Time</Th>
        </Tr>
      </Thead>
      <Tbody>
        {availableCourses.map((course, index) => (
          <Tr key={index} onClick={() => onRowClick(course)} style={{ cursor: 'pointer' }}>
            <Td>{course.name}</Td>
            <Td>{course.time}</Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  )
}

const AddCourseModal: React.FC<{ isOpen: boolean; onClose: () => void }> = ({ isOpen, onClose }) => {
  const { authenticatedAxios } = useAuth()
  const toast = useToast()
  const [weekDay, setWeekDay] = useState('')
  const [availableCourses, setAvailableCourses] = useState<AvailableCourses>(null)

  useEffect(() => {
    const fetchAvailableCourses = async () => {
      if (!/^[0-6]$/.test(weekDay)) {
        return
      }

      try {
        const response = await authenticatedAxios.get(`/api/courses/available?day=${weekDay}`)
        setAvailableCourses(response.data.available || Error('No courses available'))
      } catch (err) {
        setAvailableCourses(Error('Failed to fetch available courses'))
      }
    }

    fetchAvailableCourses()
  }, [weekDay, authenticatedAxios, toast])

  const handleSubmit = async (course: AvailableCourse) => {
    try {
      await authenticatedAxios.post('/api/courses', { weekDay, name: course.name, time: course.time })
      mutate('/api/courses')
      onClose()
    } catch (err) {
      toast({
        title: 'Failed to add the course.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    }
  }

  const handleChangeWeekDay = async (value: string) => {
    setAvailableCourses(null)
    setWeekDay(value)
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add New Course</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl id="weekDay" mb={4}>
            <FormLabel>Weekday</FormLabel>
            <Select placeholder="Select weekday" value={weekDay} onChange={(e) => handleChangeWeekDay(e.target.value)}>
              <option value="0">Sunday</option>
              <option value="1">Monday</option>
              <option value="2">Tuesday</option>
              <option value="3">Wednesday</option>
              <option value="4">Thursday</option>
              <option value="5">Friday</option>
              <option value="6">Saturday</option>
            </Select>
          </FormControl>
          <VisibleIf condition={weekDay !== ''}>
            <AvailableCourses availableCourses={availableCourses} onRowClick={handleSubmit} />
          </VisibleIf>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default AddCourseModal
