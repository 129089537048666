import React, { useContext, createContext, ReactNode, FunctionComponent, useMemo } from 'react'
import { useLocalStorage } from 'react-use'
import { TelegramUser } from 'telegram-login-button'
import axios, { AxiosInstance } from 'axios'
import { jwtDecode } from 'jwt-decode'
import { z } from 'zod'
import { safe } from '../utils/safe'

interface UserDetails {
  username: string
  id: number
}

interface AuthContextType {
  login: (user: TelegramUser) => Promise<void>
  logout: () => Promise<void>
  userToken: string | undefined
  userDetails: UserDetails | undefined
  authenticatedAxios: AxiosInstance
}

const AuthContext = createContext<AuthContextType | undefined>(undefined)

interface AuthProviderProps {
  children: ReactNode
}
const AuthProvider: FunctionComponent<AuthProviderProps> = ({ children }) => {
  const [userToken, setUserToken, removeUserToken] = useLocalStorage<string>('user-token')

  const jwtSchema = z.object({ username: z.string(), id: z.number() })

  const login = async (user: TelegramUser): Promise<void> => {
    const response = await axios.post('/api/sessions', user)
    const userToken = response.data.token
    jwtSchema.parse(jwtDecode<UserDetails>(userToken!))
    setUserToken(userToken)
  }

  const logout = async () => {
    removeUserToken()
  }

  const userDetails = useMemo(() => {
    return safe(() => jwtSchema.parse(jwtDecode<UserDetails>(userToken!)))
  }, [userToken])

  const authenticatedAxios = useMemo(() => {
    return axios.create({
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    })
  }, [userToken])

  const value = { login, logout, userToken, userDetails, authenticatedAxios }
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

const useAuth = (): AuthContextType => {
  const authContext = useContext(AuthContext)
  if (!authContext) {
    throw new Error('useAuth must be used within an AuthProvider')
  }
  return authContext
}

export { AuthProvider, useAuth }
