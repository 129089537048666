import React from 'react'
import { Th, Td, AlertTitle, Spacer, Container, Alert, AlertIcon, Table, Thead, Tr, Tbody, SkeletonText } from '@chakra-ui/react'

interface LoaderProps {
  error: boolean
  columns: string[]
  numRows: number
}

const Loader: React.FC<LoaderProps> = ({ error, columns, numRows }) => (
  <Container>
    {error && (
      <>
        <Alert status="error">
          <AlertIcon />
          <AlertTitle>Failed to fetch data</AlertTitle>
        </Alert>
        <Spacer height={5} />
      </>
    )}
    <Table variant="simple">
      <Thead>
        <Tr>
          {columns.map((column, index) => (
            <Th key={index}>{column}</Th>
          ))}
        </Tr>
      </Thead>
      <Tbody>
        {[...Array(numRows)].map((_, rowIndex) => (
          <Tr key={rowIndex}>
            {columns.map((_, colIndex) => (
              <Td key={colIndex}>
                <SkeletonText noOfLines={1} />
              </Td>
            ))}
          </Tr>
        ))}
      </Tbody>
    </Table>
  </Container>
)

export default Loader
