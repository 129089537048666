import React from 'react'
import useSWR from 'swr'
import { Card, CardBody, Text } from '@chakra-ui/react'

const HelthCheck = () => {
  const fetcher = async (url: string) => {
    const response = await fetch(url)
    if (!response.ok) {
      throw new Error('Failed to fetch data')
    }
    return response.json()
  }
  const url = '/api/health-check'
  const { data, error } = useSWR(url, fetcher)

  let value = 'Loading...'
  if (error != null) value = 'Error: ' + error.message
  if (data != null) value = JSON.stringify(data)

  return (
    <Card>
      <CardBody>
        <Text>{value}</Text>
      </CardBody>
    </Card>
  )
}

export default HelthCheck
