import React from 'react'

type Props = {
  condition: boolean
  children: JSX.Element
}

const VisibleIf = (props: Props) => {
  if (!props.condition) {
    return <></>
  }

  return props.children
}

export default VisibleIf
